import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import React from "react";

const PostSummary = ({ post }) => {
  return (
    <div key={post.id} className="group relative">
      <div className="aspect-w-4 aspect-h-3 overflow-hidden rounded-lg bg-gray-100">
        <GatsbyImage
          className="object-cover object-center"
          alt={post.name}
          image={post.summaryImage.asset.gatsbyImageData}
          style={{
            position: "absolute",
            overflow: "auto",
          }}
        />
        <div
          className="flex items-end p-4 opacity-0 group-hover:opacity-100"
          aria-hidden="true"
        >
          <div className="w-full rounded-md bg-white bg-opacity-75 py-2 px-4 text-center text-sm font-medium text-gray-900 backdrop-blur backdrop-filter">
            View details
          </div>
        </div>
      </div>
      <div className="mt-4 flex items-center justify-between space-x-8 text-base font-medium text-gray-900">
        <h3>
          <Link to={`/p/${post.id}`}>
            <span aria-hidden="true" className="absolute inset-0" />
            {post.name}
          </Link>
        </h3>
      </div>
    </div>
  );
};

export default PostSummary;
