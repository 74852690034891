import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
} from "@heroicons/react/20/solid";
import React, { useState, useEffect } from "react";
import { Link } from "gatsby";

const Pagination = ({ currentPage, numPages }) => {
  const [pages, setPages] = useState([]);

  useEffect(() => {
    const newPages = [];
    for (let i = 0; i < numPages; i++) {
      newPages.push({
        number: i + 1,
        isActive: i + 1 === currentPage,
      });
    }
    setPages(newPages);
  }, [numPages]);

  return (
    <nav className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0">
      <div className="-mt-px flex w-0 flex-1">
        {currentPage > 1 && (
          <Link
            to={`/${currentPage - 1 === 1 ? "" : currentPage - 1}`}
            className="inline-flex items-center border-t-2 border-transparent pt-4 pr-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
          >
            <ArrowLongLeftIcon
              className="mr-3 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            Previous
          </Link>
        )}
      </div>
      <div className="hidden md:-mt-px md:flex">
        {pages.map((page) => (
          <Link
            to={`/${page.number === 1 ? "" : page.number}`}
            className={
              page.isActive
                ? "inline-flex items-center border-t-2 border-indigo-500 px-4 pt-4 text-sm font-medium text-indigo-600"
                : "inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
            }
            key={page.number}
          >
            {page.number}
          </Link>
        ))}
      </div>
      <div className="-mt-px flex w-0 flex-1 justify-end">
        {currentPage !== numPages && (
          <Link
            to={`/${currentPage + 1}`}
            className="inline-flex items-center border-t-2 border-transparent pt-4 pl-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
          >
            Next
            <ArrowLongRightIcon
              className="ml-3 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </Link>
        )}
      </div>
    </nav>
  );
};

export default Pagination;
