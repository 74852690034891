import { graphql } from "gatsby";
import React from "react";

import Footer from "../components/footer";
import Header from "../components/header";
import HeadContent from "../components/head";
import Hero from "../components/hero";
import Newsletter from "../components/newsletter";
import PostGrid from "../components/post-grid";
import ResourcesCTA from "../components/resources-cta";

const IndexPage = ({ data, pageContext }) => {
  return (
    <>
      <Header />
      <Hero />
      <PostGrid posts={data.posts} pageContext={pageContext} />
      <ResourcesCTA />
      <Newsletter />
      <Footer />
    </>
  );
};

export default IndexPage;

export const Head = ({ location }) => (
  <HeadContent
    title="Using Tailwind - Discover beautiful sites created using Tailwind CSS"
    location={location}
  />
);

export const pageQuery = graphql`
  query AllPostsQuery($limit: Int!, $skip: Int!) {
    posts: allSanityPost(
      sort: { fields: [date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id: _id
          name
          summaryImage {
            asset {
              gatsbyImageData(
                placeholder: BLURRED
                layout: CONSTRAINED
                width: 600
              )
            }
          }
        }
      }
    }
  }
`;
