import React from "react";
import { Link } from "gatsby";

const ResourcesCTA = () => {
  return (
    <div className="mx-auto max-w-2xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
      <div className="rounded-md bg-blue-50 p-4">
        <div className="flex">
          <div className="ml-3 flex-1 md:flex md:justify-between">
            <p className="text-sm text-blue-700">
              Build your next Tailwind CSS project faster by browsing free and
              paid Tailwind CSS resources
            </p>
            <p className="mt-3 text-sm md:mt-0 md:ml-6">
              <Link
                to="/resources"
                className="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600"
              >
                Browse Resources
                <span aria-hidden="true"> &rarr;</span>
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResourcesCTA;
