import React from "react";

import Pagination from "../pagination";
import PostSummary from "../post-summary";

const PostGrid = ({ posts, pageContext }) => {
  if (!posts?.edges.length) return null;

  return (
    <div className="bg-white">
      <div className="mx-auto max-w-2xl py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
        <div className="mt-6 grid grid-cols-1 gap-x-8 gap-y-8 sm:grid-cols-2 sm:gap-y-10">
          {posts.edges.map((post) => (
            <PostSummary key={post.node.id} post={post.node} />
          ))}
        </div>
        <div className="mt-10">
          <Pagination
            currentPage={pageContext.currentPage}
            numPages={pageContext.numPages}
          />
        </div>
      </div>
    </div>
  );
};

export default PostGrid;
